import axios from "axios";
import { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";

function App() {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [promptResponses, setPromptResponses] = useState([]);
  // const [responseData, setResponseData] = useState(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (e) => {
    // Check if the Enter key is pressed (key code 13)
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(inputValue);
    setLoading(true);
    setInputValue("");
    try {
      // const response = await axios.get(endpoint);
      const requestData = {
        message: inputValue,
      };
      const response = await axios.post("/chat", requestData);
      console.log(response);
      const newPrompt = {
        prompt: inputValue,
        response: response.data.copy,
      };
      setPromptResponses([...promptResponses, newPrompt]);
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="border border-gray-300 shadow-xl rounded-xl py-3 px-4 w-[610px] max-h-[600px] overflow-auto relative">
        <div
          className={`absolute -right-7 top-3 ${loading ? "block" : "hidden"}`}
        >
          <ThreeCircles height="15" color="purple" ariaLabel="loading" />
        </div>
        <div className="flex items-center pb-2 gap-x-1 mb-1">
          <img src="ai_logo.svg" alt="" />
          <h3 className="text-purple-900 font-medium">Ai Assistant</h3>
        </div>
        {promptResponses.map((prompt, index) => {
          return (
            <div key={index}>
              <Prompt promptData={prompt} />
            </div>
          );
        })}
        <form onSubmit={(e) => onSubmit(e)} className="">
          {/* <div contentEditable="true" value={inputValue} onChange={handleInputChange} defaultValue="Ask me to write anything... or start by telling me what your ad is about" className='px-2 text-sm text-gray-500 border border-solid border-gray-300 w-full focus:border-gray-300 focus:ring-gray-300 focus:ring-1 focus:outline-none resize-y max-h-[200px]'></div> */}
          <textarea
            onKeyDown={handleKeyPress}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Ask me to write anything... or start by telling me what your ad is about"
            className="px-2 pt-1 text-sm text-gray-500 border border-solid border-gray-300 w-full focus:border-gray-300 focus:ring-gray-300 focus:ring-1 focus:outline-none resize-y max-h-[200px]"
          ></textarea>
        </form>
      </div>
    </div>
  );
}

const Prompt = (props) => {
  const promptData = props.promptData;
  return (
    <div className="pb-3">
      <h4 className="text-xs font-bold text-gray-800 mb-1">
        {promptData.prompt}
      </h4>
      <p className="text-sm text-gray-800 leading-6 whitespace-pre-wrap">
        {promptData.response}
      </p>
    </div>
  );
};

export default App;
